.reports-app-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}

.reports-container {
  display: flex;
  justify-content: center;
}

.reports-add-remove-container {
  display: flex;
  gap: 20x;
  margin-left: auto;
  margin-right: 100px;
  color: white;
}

.reports-remove-text, .reports-upload-text {
  margin-top: 3px;
  font-size: 20px;
  text-align: center;
}

.reports-add-remove-option-box {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  border-radius: 10px;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  font-size: 30px;
}

.reports-add-remove-option-box:hover {
  transform: scale(1.05);
}

/* Reports Section Container */
.reports-software-sections {
  display: flex;
  flex-direction: column;
}

.reports-software-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
}

/* Fade-in Effect for the Title */
.reports-software-title {
  font-size: 100px;
  font-weight: 400;
  color: white;
  text-align: center;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 1s ease-out forwards; /* Fade-in animation */
  margin-bottom: 10px; /* Adjust margin for spacing */
}

/* Fade-in Effect for the Description */
.reports-software-description {
  color: white;
  font-size: 25px;
  width: 1000px;
  font-weight: normal;
  margin-top: 50px;
  text-align: center;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 1.5s ease-out forwards; /* Fade-in animation with delay */
  animation-delay: 0.5s; /* Staggered delay after title */
  color: var(--primary-100);
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.reports-centering-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.reports-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}



.reports-desc-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reports-software-description {
  text-align: left;
  line-height: 1.5;
  width: 1000px;
  font-size: 25px;
  font-weight: normal;
  margin-top: 50px;
  color: var(--primary-100);
}

.reports-more-button {
  animation: fadeIn 1.5s ease-out forwards; 
  font-weight: normal;
  background-color: white;
  width: 150px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 30px;
  opacity: 0;
  animation: fadeIn 2s ease-out forwards; /* Fade-in animation for the button */
  animation-delay: 1s;
}

.reports-more-button:hover {
  background-color: #000;
  color: white;
  border: 0.1px solid white;
}

.reports-app-footer {
  justify-content: center;
  gap: 50px;
}

.reports-options-container {
  display: flex;
  gap: 50px; flex-direction: column;
  margin-top: 5vh;
}

.reports-option-box {
  width: 300px;
  height: 400px;
  color: white;
  background-color: rgba(0, 0, 255, 0.1);
  border: 3px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  padding-bottom: 30px;
  padding-top: 20px; padding-left: 30px; padding-right: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.reports-option-box:hover {
  background-color: #2f2f2f;
}

.reports-option-box h2 {
  width: auto;
  font-weight: bold;
  font-size: 40px;
  padding: 5px;
  border-radius: 20px; margin-top: 0px;
}

.reports-option-box p {
  margin-top: -25px;
  margin-left: 5px;
  font-size: 22px;
  color: rgb(240, 240, 240);
}


.reports-file-actions-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 255, 0.1);
  border: 3px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  padding-left: 40px; padding-bottom: 20px;
  border-radius: 5px;

}

.reports-file-top-container {
  display: flex;
  margin-top: 25px;
  margin-bottom: 50px;
}

.reports-file-top-bar{
  display: flex;
  gap: 100px; margin-top: 20px; margin-bottom: 20px;

}

.reports-file-count {
  font-size: 25px; color: white; margin-left: 20px;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  padding: 10px; border-radius: 5px;
  width: 115px;
}

.reports-file-search-bar {
  font-size: 20px; color: white; margin-left: 20px;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  padding: 10px; border-radius: 5px;
  width: 15vw;
}

.reports-file-list-search-title {
  color: white;
  font-size: 22px;
  margin-right: 20px;
}

.reports-file-list-title {
  font-size: 20px;
  font-weight: normal;
  margin-left: auto;
  color: white;
}

.reports-file-search-bar input {
  padding: 15px;
  color: white;
  font-size: 16px;
  border: none;
  width: 300px;
  outline: none;
  margin-right: 50px;
  border-radius: 5px;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
}

.reports-centering-file-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.reports-file-list-container {
  padding-right: 30px;
  width: 50vw;
  height: 65vh;
  overflow-x: hidden;
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: black transparent; /* White thumb with transparent track */
  scroll-behavior: smooth; 
}

.reports-file-list-container::-webkit-scrollbar {
  width: 12px; /* Thicker scrollbar */
}

.reports-file-list-container::-webkit-scrollbar-track {
  background: transparent; /* Transparent background for track */
}

.reports-file-list-container::-webkit-scrollbar-thumb {
  background-color: black; /* White scrollbar thumb */
  border-radius: 20px; /* Fully rounded scrollbar (more oval shape) */
  border: 3px solid transparent; /* Space around the thumb to enhance the oval effect */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for a modern look */
}

.reports-file-list-container::-webkit-scrollbar-button {
  display: none; /* Remove the up/down arrows */
}

.reports-scrollable-file-list {
  font-size: 20px;
  overflow: hidden;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
}

.reports-file-item {
  padding: 15px;
  font-weight: normal;
  user-select: none;
  background-color: black;
  border: 1px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  color: white;
  cursor: pointer;
  
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.reports-file-item.reports-selected {
  background-color: var(--neutral-700); /* Selected color */
}

.reports-file-item:hover {
  background-color: var(--neutral-800); /* Hover color */
}

.reports-mini-container {
  border: 3px solid white;
  display: flex;
  justify-content: center; gap: 20px;
  padding: 20px;
  padding-right: 3px;
  border-radius: 5px;
  margin-top: 20px; margin-bottom: 20px;
}

.reports-option-logo {
  width: 70px;
  height: auto;
  display: block;
  margin-right: auto;
  filter: invert(100%);
}

.reports-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.reports-overlay.reports-visible {
  visibility: visible;
  opacity: 1;
}

.reports-loading-message {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.reports-file-remove-button, .reports-file-upload-button {
  width: 40px;
  color: white;
  filter: invert(1);
}
