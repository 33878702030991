.workorder-container {
    border-radius: 10px;
    color: white;
    background-color: rgba(0, 0, 255, 0.1);
  border: 3px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  margin-top: 50px;
  height: 80vh;
  width: 80vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;

  }
  
  .workorder-right-container{
    background-color: black;
    width: 55vw;
    margin-left: auto;
    margin-right: auto;
  border-left: 2px solid white;
  overflow: auto;
  
  }


  .workorder-form {
    display: flex;
    width: 10vw;
 
  }
  
  .workorder-input {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    outline: none;

  }
  
  .workorder-input::placeholder {
    color: rgba(63, 63, 63, 0.7);
  }

  .workorder-button {
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .workorder-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
  
  .workorder-loading {
    text-align: center;
    font-size: 18px;
  }
  
  .workorder-error {
    text-align: center;
    color: red;
    font-size: 16px;
  }
  
  .workorder-summary {
    color: white;
    line-height: 1.3;
    font-size: 20px;
  

  }
  
  .workorder-summary h2 {
    margin-bottom: 10px;
  }
  

  .workorder-instructions-text{
    margin-left: 10px; margin-top:40px; margin-bottom: 10px;

  }

  .workorder-suggestions {
    color: white;
    width: 20vw;
    margin-top: 30px;
    margin-right: 20px;
    line-height: 1.25;
    overflow-x: hidden;
    padding-right: 20px;
    overflow-y: hidden;
}


  .workorder-checkbox{
    display: flex;
    margin-bottom: 5px;

  }

  .workorder-left-menu {
    margin-top: 20px;
    margin-left: 20px;
    height: 95%; /* Make it take full height */
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    overflow: hidden; /* Prevent content from leaking */
}

