/* Container for the handbook page */
.handbook-page-container {
    display: flex;
    height: 90vh;
    flex-direction: column;
    align-items: center;
    
}

.handbook-chatbot-notice-text{
    color: white;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    margin-top: auto;
    margin-top: 5px;
}

.handbook-inside-container{
    display: flex;
    flex-direction: column;
    height: 85vh;
    margin-top: 20px;
    background-color: rgba(0, 0, 255, 0.1);
    border: 2px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
}

.handbook-form{
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 20px;
}

/* Handbook search section styling */
.handbook-search-section {
    
    display: flex;
    flex-direction: column;
    align-items: center;

  
}

.handbook-input-field {
    width: 500px;
    height: 15px;
    padding: 12px;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-left: auto;
    margin-right: auto;
}

/* Error message styling */
.handbook-error-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
}

/* Response container styling */
.handbook-response-container {
    background-color: black;
    width: 50vw;
    border-radius: 5px;
    height: 73.5vh;
    margin-top: 15px; margin-left: 20px; margin-right: 20px; margin-bottom: 15px;
    border: 2px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
    font-weight: normal; overflow: auto;
    padding: 20px;
    color: white;
    overflow: auto;
}

/* Handbook response list styling */
.handbook-response ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.handbook-response li {
    padding-left: 20px;
    text-indent: -20px;
    margin-bottom: 10px;
    color: white;
}


/* Loading spinner */
.loading-spinner {
    font-size: 16px;
    color: white;
    margin-top: 10px;
    text-align: center;
    animation: blink 1s infinite; /* Blinking dots effect */
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* User message styling */
  .user-message {
    align-self: flex-end;
    color: white;
    padding: 8px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  /* Model message styling */
  .model-message {
    align-self: flex-start;
    color: white;
    padding: 8px;
    border-radius: 10px;
    margin: 5px 0;
    max-width: 80%;
    word-wrap: break-word;
  }
  
/* Loading spinner */
.loading-spinner {
    font-size: 16px;
    color: white;
    margin-top: 10px;
    text-align: center;
    animation: blink 1s infinite; /* Blinking dots effect */
}

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

/* Message container for alternating alignment */
.handbook-message {
    border-radius: 10px;
    word-wrap: break-word;
}

/* Messages aligned to the left */
.right-message {
    margin-left: auto; width: 20vw; margin-top: 20px; margin-right: 2vw;
    color: white;
    background-color: rgba(0, 0, 255, 0.1);
    padding: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
    
}

.left-message {
    margin-right: auto; width: 30vw; margin-top: 20px; 
    color: white; 
}
