/* Container styles for Employee section */
.employee-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.employee-sections {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
}

.employee-mini-container {
  display: flex;
  align-items: center;
}

.employee-file-list-container {
  padding: 10px;
  width: 700px;
  overflow-x: hidden;
  margin-left: auto;
  border-radius: 5px;
  overflow-y: auto;
}

.employee-centering-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin-bottom: 400px;
}

.employee-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

/* Styling for title and description */
.employee-title {
  font-weight: 400;
  font-size: 100px;
  color: white;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  animation: fadeIn 1s ease-out forwards; /* Fade-in animation for the title */
  margin-bottom: 10px;
}

.employee-description {
  line-height: 1.5;
  font-size: 25px;
  font-weight: normal;
  margin-top: 50px;
  color: white;
  width: 1000px;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  animation: fadeIn 1.5s ease-out forwards; /* Fade-in animation for description */
  animation-delay: 0.5s; /* Staggered delay after title */
}

.employee-more-button {
  display: flex;
  font-weight: normal;
  background-color: white;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  cursor: pointer;
  margin-left: auto;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  animation: fadeIn 2s ease-out forwards; /* Fade-in animation for the button */
  animation-delay: 1s; /* Staggered delay after description */
}

.employee-more-button:hover {
  background-color: #000;
  color: white;
  border: 0.1px solid white;
}

/* Additional containers and button hover effects */
.employee-add-remove-container {
  display: flex;
  gap: 50px;
  margin: 0 auto;
  margin-top: 10px;
}

.employee-add-remove-option-box:hover {
  transform: scale(1.05);
}

.employee-section{
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-bottom: 200px;
}

/* Option box styling */
.employee-options-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  gap: 50px;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  
}

.employee-option-box {
  color: white;
  width: 500px;
  background-color: black;
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  padding: 50px;
  padding-bottom: 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.employee-option-box:hover {
  background-color: #2f2f2f;
}

.employee-option-box h2 {
  margin-top: 0px;
  width: auto;
  font-weight: bold;
  font-size: 50px;
  padding: 5px;
  border-radius: 20px;
}

.employee-option-box p {
  margin-top: -15px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 25px;
  color: white;
}

.employee-inner-borders {
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
}

.employee-file-actions-container {
  display: flex;
  flex-direction: column;
  width: 60vw;
}

.employee-add-remove-option-box {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: white;
  transition: transform 0.3s ease;
}

.employee-add-remove-option-box:hover {
  transform: scale(1.1);
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
