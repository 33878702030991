.app-header {
  width: 100%; /* Full width */
  min-width: 100vw;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  backdrop-filter: blur(12px);
  background-color: rgba(0, 0, 0, .56);
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: space-between; /* Distribute space between items */
  padding: 20px;
  box-sizing: border-box;
  position: sticky; 
  top: 0; 
  z-index: 10;
  min-height: 120px;
}

.geolabs-img {
  margin-left: 30px;
  cursor: pointer;
  margin-top: 5px;
  width: 60px;
}

.geolabs-img:hover {
  transform: scale(1.05);
}

.logo {
  font-size: 35px;
  margin-left: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.logo:hover {
  transform: scale(1.02);
}

.nav-buttons {
  display: flex; 
  gap: 15px;
  margin: 0 auto;
  flex: 1; /* Allows it to take available space in the middle */
  justify-content: center; margin-right: 100px;
}

.nav-button:hover{
  color: gray;
}

.auth-buttons {
  display: flex;
  gap: 15px;
  margin-right: 60px;
  gap: 20px;
}

.nav-button, .auth-button {
  text-decoration: none;
  outline: none;
  border: none;
  color: white;
  background-color: rgba(0, 0, 0, .1);
  font-size: 22px;
  letter-spacing: 0.1px;
  cursor: pointer;
  text-align: center; 
  padding: 5px 15px;
}

.header-register-button {
  background-color: white;
  color: #000;
}

.header-register-button:hover {
  background-color: #000;
  color: white;
  border: 0.5px solid white;
}

.header-login-button:hover {
  color: gray;
}
