.main-app-container {
    width: 100vw;
}

.main-main-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main-options-container {
    
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Introduction Container Styles */
.main-software-intro-container {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.main-software-intro-text-container {
    margin-bottom: 30vh;
}

.main-software-title {
    font-weight: normal;
    letter-spacing: 2px;
    font-size: 100px;
    color: white;
    opacity: 0; /* Start with opacity 0 for fade-in effect */
    animation: fadeIn 1s ease-out forwards; /* Apply fade-in animation */
}

.main-software-description {
    font-size: 25px;
    line-height: 1.5;
    color: white;
    font-weight: normal;
    max-width: 1200px;
    margin: 0 auto;
    opacity: 0; /* Start with opacity 0 for fade-in effect */
    animation: fadeIn 1.5s ease-out forwards; /* Apply fade-in animation */
    animation-delay: 0.5s; /* Delay the description animation for a staggered effect */
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px); /* Optional: Slight upward movement */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.main-options {
    padding: 20px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    background-color: rgba(0, 0, 255, 0.1);
    border: 3px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
    margin-top: 200px; margin-bottom: 200px;
    border-radius: 5px;
}

.main-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

.main-button-style {
    padding: 20px;
    cursor: pointer;
    color: white;
    font-size: 40px;
    border: 2px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
    background-color: black;
    border-radius: 10px;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-align: center;
  }
  

.main-button-style:hover {
    background-color: #2f2f2f;
}

.main-button-description-style {
    font-size: 25px;
    color: white;
    font-weight: normal;
    margin-top: 30px;
}


.main-reports-img {
    width: 800px; border-radius: 5px; display: flex;
    border: 2px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  
}

.main-employee-guide-img {
    width: 700px; border-radius: 5px; display: flex;
    border: 2px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 1);
    
}

.main-employee-guide-container, 
.main-reports-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Adjust spacing between elements */
  padding: 20px;
}



.main-employee-guide-container,
.main-reports-container {
  justify-content: center;
  position: relative;
}


.main-admin-container {
    justify-content: flex-end;
}


.main-reports-arrow{
    width: 5vw;
}

.main-employee-arrow{
    width: 5vw;
    transform: scaleX(-1);
}