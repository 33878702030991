::-webkit-scrollbar {
  width: 16px; /* Adjust width for vertical scrollbars */
  height: 16px; /* Adjust height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background-color: var(--neutral-900); /* Darker background for the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: white; /* White color for the scrollbar thumb */
  border-radius: 8px; /* Rounded edges for the thumb */
  border: 3px solid var(--neutral-900); /* Adds padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--neutral-200); /* Lighter color on hover */
}


html, body {
  font-weight: 400;

  width: 100%;
  height: 100%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  justify-content: center;
  align-items: stretch;
  background-color: #000;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

:root {
 
    /* Primary: Chun-Li Blue */
    --primary-100: #F2F2FF;
    --primary-200: #BEC2FE;
    --primary-300: #8996FB;
    --primary-400: #526EF0;
    --primary-500: #1F4BDB;
    --primary-600: #0A3AAE;
    --primary-700: #022F81;
    --primary-800: #002254;
    --primary-900: #001226;

    /* Accent: Sabz Green */
    --accent-100: #F9FFF2;
    --accent-200: #E1FEBD;
    --accent-300: #CDFA86;
    --accent-400: #BAEE4E;
    --accent-500: #A4D61A;
    --accent-600: #89AA09;
    --accent-700: #6C7E02;
    --accent-800: #4B5200;
    --accent-900: #252600;

    /* Neutral */
    --neutral-100: #FAFAFCdd;
    --neutral-200: #E6E6EDdd;
    --neutral-300: #D3D3DDdd;
    --neutral-400: #C0C2CDdd;
    --neutral-500: #AEB1BDdd;
    --neutral-600: #8A8E97dd;
    --neutral-700: #676A71dd;
    --neutral-800: #45474Cdd;
    --neutral-900: #222426dd;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}


.app-footer{
  display: flex; font-weight: normal;
  min-width: 100vw; font-size: 20px;
  color: white;
}
